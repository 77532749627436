<div class="container-fluid">
  <br>
  <h2 class="  mb-3 fw-bold">Orcaa.ai Suite </h2>
  <br>
  <ul class="nav nav-tabs features-tabs" id="navigationTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ active: selectedTab === 'tab1' }"
        id="Education-tab"
        type="button"
        @click="handleTabChange('tab1')"
      >
      Assist 
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ active: selectedTab === 'tab3' }"
        id="Corporates-tab"
        type="button"
        @click="handleTabChange('tab3')"
      >
      Internship  
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ active: selectedTab === 'tab4' }"
        id="Parents-tab"
        type="button"
        @click="handleTabChange('tab4')"
      >
      Plus
      </button>
    </li>    
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ active: selectedTab === 'tab2' }"
        id="Internship-tab"
        type="button"
        @click="handleTabChange('tab2')"
      >
      X
      </button>
    </li>
  </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="cardContent">
      <!-- Assist Suite Section -->
      <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab1' }]">
        <div class="  features-detail-card border-0 border-radius">
          <br>
          <div class=" container-fluid">
            <div class=" usecase-scroll">
              <img class="img-fluid h-200" src="@/assets/images/orcaa-corp-suit-1.png" alt="Orcaa logo">
              <img class="img-fluid h-200 ms-3" src="@/assets/images/orcaa-corp-suit-2.png" alt="Orcaa logo">

              <img class=" h-200 ms-3" src="@/assets/images/orcaa-corp-suit-3.png" alt="Orcaa logo">
            </div>
            <br>
            <h4 class="question-text text-center ms-md-5 mt-1">What is Orcaa <b>Assist</b>?</h4>
            <p class="question-text-info text-center ms-md-5">Addresses the problem of limited visibility for parents, tutors, and mentors into students’ academic progress, which can hinder timely support. 
              The platform provides real-time updates and integrates with school systems to deliver timely assessments, interactive learning tools, and personalized feedback.
            </p>
            <br>
            <div class="orcaa-suite-feature-container">
              <div class="orcaa-suite-feature-row"> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Real-Time Academic Updates</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Parents receive instant updates on their children’s academic progress and achievements, helping them stay informed.
                       </div>
                    </div>
                  </div>
                </div> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-blue"  >
                         
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Integrated Behavioural Insights</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Track behavioural patterns to provide holistic support for student development.
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Scheduled Progress Reports</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Parents can set up recurring course progress reports to stay updated on their learner’s growth.                     
                      </div>
                    </div>
                  </div>
                </div>   

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Parent-Purchased Intervention Courses</h3> 
                      <div class="orcaa-suite-feature-description  ">
                      Parents can purchase extra courses as part of intervention support, allowing tailored academic assistance.
                      </div>
                    </div>
                  </div>
                </div>    
              </div>
            </div>
             
          </div>
        </div> 
      </div>

      <!--  Corporate Auditing Features Section -->
      <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab2' }]">
        <div class="  features-detail-card">

          <div class="container-fluid"> 
            <div class=" usecase-scroll">
              <img class="img-fluid h-200" src="@/assets/images/orcaa-corp-suit-1.png" alt="Orcaa logo">
              <img class="img-fluid h-200 ms-3" src="@/assets/images/orcaa-corp-suit-2.png" alt="Orcaa logo">

              <img class=" h-200 ms-3" src="@/assets/images/orcaa-corp-suit-3.png" alt="Orcaa logo">
            </div>
            <br>
            <h4 class="question-text text-center ms-md-5 mt-1">What is Orcaa <b>Corporate</b>?</h4>
            <p class="question-text-info text-center ms-md-5">
              AI-driven skills evaluation platform dedicated to enhancing workplace skills by integrating directly into your existing corporate systems.
            </p>
            <br>
            <div class="orcaa-suite-feature-container">
              <div class="orcaa-suite-feature-row"> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Define Employee Training Skills</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Identify and outline specific skills required for employee development programs.
                      </div>
                    </div>
                  </div>
                </div> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-blue"  >
                         
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Link Training to Company KPIs</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Align training programs with key performance indicators.
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">AI-Powered Adaptive Assessments</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Implement intelligent assessments that adapt to employee progress.
                      </div>
                    </div>
                  </div>
                </div>   

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Customisable Skills Auditing Workflow</h3> 
                      <div class="orcaa-suite-feature-description  ">
                         Tailor workflows to align skills auditing with your company’s strategic goals.
                      </div>
                    </div>
                  </div>
                </div>  
                
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Host Paid or Free Training Modules</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Provide flexibility in offering training content to meet diverse employee development needs.
                      </div>
                    </div>
                  </div>
                </div>   

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Automated and Scheduled Assessment</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Set up assessments that adapt to each employee’s progress.                      
                      </div>
                    </div>
                  </div>
                </div>   
              </div>
            </div>

            <div class="card product-background-card ms-5 mt-5 p-3 me-5 d-none">
              <div class="row">
                <!-- Left Column -->
                <div class="col-12 col-md-6">
                  
        
                  <div class="line-with-text ms-md-5 mt-5">
                    <div class="vertical-line-metrics"></div>
                    <ul class="metrics-description ps-0">
                      <li class="ms-2"><b>Key Metrics</b></li>
                      <li class="text-muted">
                        <box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> +150 Upskilling Candidates
                      </li>
                      <li class="text-muted">
                        <box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> +7 Partner Companies
                      </li>
                      <li class="text-muted">
                        <box-icon name='cog' color="#3e1590" class='metric-icon'></box-icon> 10+ Corporate Bootcamps hosted
                      </li>
                    </ul>
                  </div>
                </div>
        
                <!-- Right Column -->
                <div class="col-12 col-md-6">
                  <div class="product-card recruit-content mt-4 mt-md-0">
                    <p class="product-title text-center">Benefits of Orcaa Corporate</p>
                    <div class="row">
                      <h5 class="text-start fw-bold mini-product-topics">Link Training to Company KPIs:</h5>
                      <p class="text-start mini-product-desc">Align training programs with key performance indicators.</p>
                      <h5 class="text-start fw-bold mini-product-topics">AI-Powered Adaptive Assessments:</h5>
                      <p class="text-start mini-product-desc">Implement intelligent assessments that adapt to employee progress.</p>
                      <h5 class="text-start fw-bold mini-product-topics">Skills-Audited Certifications:</h5>
                      <p class="text-start mini-product-desc">Issue certificates validating employees' skill achievements.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>       
          </div>
        </div>
      </div>

      <!--Internship Features Section -->
      <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab3' }]">
        <div class="  features-detail-card">
          <div class=" container-fluid">
            <div class=" usecase-scroll">
              <img class="img-fluid h-200" src="@/assets/images/orcaa_intern_suit-1.png" alt="Orcaa logo">
              <img class="img-fluid h-200 ms-3" src="@/assets/images/orcaa_intern_suit-2.png" alt="Orcaa logo">

              <img class=" h-200 ms-3" src="@/assets/images/orcaa_intern_suit-3.png" alt="Orcaa logo">
            </div>
            <br>
            <h4 class="question-text text-center ms-md-5 mt-1">What is Orcaa <b>Internship</b>?</h4>
            <p class="question-text-info text-center ms-md-5">OrcaaI solves the problem of the disconnect between academic learning and the practical skills needed in the workplace. 
              It ensures that interns are matched to roles aligned with their strengths, monitors their progress, and provides real-time feedback to prepare them for full-time employment.
            </p>
            <br>
            <div class="orcaa-suite-feature-container">
              <div class="orcaa-suite-feature-row"> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Advertise Internship Opportunities: </h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Promote internships effectively across channels.
                      </div>
                    </div>
                  </div>
                </div> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-blue"  >
                         
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Customisable Onboarding Workflow</h3> 
                      <div class="orcaa-suite-feature-description  ">
                       Define processes to suit organisational needs.
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">AI-Powered Adaptive Assessments</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Assess intern skills accurately.
                      </div>
                    </div>
                  </div>
                </div>   

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold"> Automated and Scheduled Assessments</h3> 
                      <div class="orcaa-suite-feature-description  ">
                       Monitor intern progress over time.
                      </div>
                    </div>
                  </div>
                </div>  
                
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">AI-Powered Marking and Feedback</h3> 
                      <div class="orcaa-suite-feature-description  ">
                         Provide efficient grading and constructive feedback.
                      </div>
                    </div>
                  </div>
                </div>  
                
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Generate Reporting on Defined Skills</h3> 
                      <div class="orcaa-suite-feature-description  ">
                         Keep track of intern development.
                      </div>
                    </div>
                  </div>
                </div> 
 
              </div>
            </div>
 
            <div class="card product-background-card ms-md-5 mt-5 p-3 me-5 d-none">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="product-card product-display-2 mt-md-0">
                    <p class="product-title text-center">Benefits of Orcaa Internship</p> 
                      <br>
                    <h5 class="text-start fw-bold mini-product-topics">Advertise Internship Opportunities:</h5>
                    <p class="text-start mini-product-desc">Promote internships effectively across channels.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Customisable Onboarding Workflow:</h5>
                    <p class="text-start mini-product-desc">Define processes to suit organisational needs.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Define & Monitor Interventions for Intern Support:</h5>
                    <p class="text-start mini-product-desc">Offer targeted assistance when needed.</p>                
                  </div>
                  </div>
                  <div class="col-12 col-md-6">
                   
                    <div class="line-with-text ms-md-5 mt-5">
                      <div class="vertical-line-metrics"></div>
                      <ul class="metrics-description ps-0">
                        <li class="ms-2"><b>Key Metrics</b></li>
                        <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> +150 Upskilling Candidates</li>
                        <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> +7 Partner Companies</li>
                        <li class="text-muted"><box-icon name='cog' color="#3e1590" class='metric-icon'></box-icon> 10+ Corporate Bootcamps hosted</li>
                      </ul>
                    </div>
                  </div> 
                </div>
              </div> 
            </div>  
          </div>
        </div>
      

      <!-- Plus Features Section -->
      <div :class="['tab-pane fade', { 'show active': selectedTab === 'tab4' }]">
        <div class="  features-detail-card">
            <!-- Features Section -->
          <div class=" container-fluid">
            <div class=" usecase-scroll">
              <img class="img-fluid h-200" src="@/assets/images/orcaa_plus_suit-1.png" alt="Orcaa logo">
              <img class="img-fluid h-200 ms-3" src="@/assets/images/orcaa_plus_suit-2.png" alt="Orcaa logo">

              <img class=" h-200 ms-3" src="@/assets/images/orcaa-corp-suit-3.png" alt="Orcaa logo">
            </div>
            <br>
            <h4 class="question-text text-center ms-md-5 mt-1">What is Orcaa <b>Plus</b>?</h4>
            <p class="question-text-info text-center ms-md-5">AI-driven platform for learner evaluation and tracking. 
              It supports students, admins, and educators in private schools, universities, and home schooling by augmenting existing educational 
              systems and providing continuous feedback.
            </p>
            <br>
            <div class="orcaa-suite-feature-container">
              <div class="orcaa-suite-feature-row"> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold"> Define Learning Skills: </h3> 
                      <div class="orcaa-suite-feature-description  ">
                       Identify and outline specific skills required for educational programs.
                      </div>
                    </div>
                  </div>
                </div> 

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-blue"  >
                         
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold"> AI-Powered Adaptive Assessments: </h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Implement assessments that adapt to each student’s progress.
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">  Customisable Skills Auditing Workflow:</h3> 
                      <div class="orcaa-suite-feature-description  ">
                       Create workflows that align with institutional requirements.
                      </div>
                    </div>
                  </div>
                </div>   

                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Audit of Accredited Courses: </h3> 
                      <div class="orcaa-suite-feature-description  ">
                         Evaluate and verify course quality and accreditation.
                      </div>
                    </div>
                  </div>
                </div>  
                
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Plagiarism Checker: </h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Ensure originality in student submissions.
                      </div>
                    </div>
                  </div>
                </div>  
                
                <div class="orcaa-suite-feature-card">
                  <div class="orcaa-suite-feature-details">
                    <div class="product-image-container bg-orcaa-suite-purple" >
                        
                    </div>
                    <div class="orcaa-suite-feature-info">
                      <h3 class="fw-bold">Skills-Audited Certificates:</h3> 
                      <div class="orcaa-suite-feature-description  ">
                        Issue certificates validating student achievements.
                      </div>
                    </div>
                  </div>
                </div> 
 
              </div>
            </div>

            <div class="card product-background-card ms-md-5 mt-5 p-3 me-5 d-none">
              <div class="row ">
                <div class="col-12 col-md-6">
                  
                  <div class="line-with-text ms-md-5 mt-5">
                    <div class="vertical-line-metrics mb-1"></div>
                      <ul class="metrics-description ps-0">
                        <li class="ms-2"><b>Key Metrics</b></li>
                        <li class="text-muted"><box-icon name='group' color="#3e1590" class='metric-icon'></box-icon> 3000+ Females trained through code like a Girl Programme</li>
                        <li class="text-muted"><box-icon name='door-open' color="#3e1590" class='metric-icon'></box-icon> 5+ Countries used in</li>
                      </ul>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="product-card plus-content mt-4 mt-md-0">
                    <p class="product-title text-center">Benefits of Orcaa Plus</p>
                    <br>
                    <h5 class="text-start fw-bold mini-product-topics">AI-Powered Adaptive Assessments:</h5>
                    <p class="text-start mini-product-desc">Implement assessments that adapt to each student’s progress.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Plagiarism Checker</h5>
                    <p class="text-start mini-product-desc">Ensure originality in student submissions.</p>
                    <h5 class="text-start fw-bold mini-product-topics">Generate Reporting on Defined Skills:</h5>
                    <p class="text-start mini-product-desc">Produce detailed skill analysis reports.</p>
                  </div>
                </div>
              </div>
            </div> 
          </div>   
        </div>
      </div> 
      
    </div> 
</div>
