export default {
  data() {
    return {
      // Countdown timer variables
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdownInterval: null,
      formData: {
        name: '',
        email: ''
      }
    };
  },
  methods: {
    validateForm() {
      // Basic form validation
      const { name, email } = this.formData;
      
      if (!name || name.trim() === '') {
        return false;
      }

      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        return false;
      }

      return true;
    },
    submitForm() {
      // First, validate the form
      if (!this.validateForm()) {
        return;
      }
      // Form submission logic here
    },
    startCountdown(eventDate) {
      const eventTime = new Date(eventDate).getTime();
      
      this.countdownInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = eventTime - now;
        
        if (distance < 0) {
          // Event has started
          clearInterval(this.countdownInterval);
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
          return;
        }
        
        // Time calculations
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    }
  },
  mounted() {
    // Start the countdown timer (replace with your actual event date)
    this.startCountdown('2025-04-25T00:00:00');
  },
  beforeUnmount() {
    // Clear the interval when component is destroyed
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
};