<div class="contact-us-image">
    <div class="container contact-us-content">
      <div class="row">
        <!-- Text Column (Left Side) -->
        <div class="col-md-6 d-flex align-items-center text-white mt-5 contact-info">
          <div>
            <h1 class="contact-us-topic mb-5">Contact Us</h1>
            <h4 class="text-start contact-us-slogan mb-5">
              For questions, technical assistance,<br>
              or collaboration opportunities via the contact information provided.
            </h4>
            <ul class="list-unstyled">
              <li class="d-flex align-items-center mb-3">
                <div class="icon-circle text-light">
                  <box-icon name='phone-call' type='solid' color="#000" class="icon text-light"></box-icon>
                </div>
                <span class="text-white ms-2">011 084 1100</span>
              </li>
              <li class="d-flex align-items-center mb-3">
                <div class="icon-circle">
                  <box-icon name='envelope' type='solid' color="#000" class="icon"></box-icon>
                </div>
                <span class="text-white ms-2">marketing@epftechhubic.org</span>
              </li>
              <li class="d-flex align-items-center mb-3">
                <div class="icon-circle">
                  <box-icon name='location-plus' type='solid' color="#000" class="icon"></box-icon>
                </div>
                <span class="text-white ms-2">35 Ferguson Road, Illovo</span>
              </li>
            </ul>
          </div>
        </div>
        
        <!-- Form Column (Right Side) -->
        <div class="col-md-6 contact-us-form mb-5">
          <form class="text-start mt-5 contact-form" id="contact-form">
            <div class="mb-3">
              <label for="name" class="form-label text-white">Name</label>
              <input 
                type="text" 
                class="form-control" 
                id="name" 
                v-model="name" 
                name="Name" 
                placeholder="Enter your name"
              >
            </div>
            
            <div class="row mb-3">
              <div class="col">
                <label for="email" class="form-label text-white">Email</label>
                <input 
                  type="email" 
                  class="form-control" 
                  id="email" 
                  v-model="email" 
                  name="Email" 
                  placeholder="Enter email"
                >
              </div>
            </div>
            
            <div class="mb-3">
              <label for="subject" class="form-label text-white">Subject</label>
              <input 
                type="text" 
                class="form-control" 
                id="subject" 
                v-model="subject" 
                name="Subject" 
                placeholder="Enter subject"
              >
            </div>
            
            <div class="mb-3">
              <label for="message" class="form-label text-white">Message</label>
              <textarea 
                class="form-control" 
                id="message" 
                v-model="message" 
                name="Message" 
                rows="4" 
                placeholder="Enter your message here"
              ></textarea>
            </div>
            
            <div class="text-end">
              <button type="submit" class="btn btn-primary login-button submit-button">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>