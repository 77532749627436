<h6 class="display-6 fw-bold mb-5 mt-5 py-3">Exclusive Preview</h6>
<div class="container py-3 bg-light px-3 features-intro">
  <div class="row g-4">
    <div class="col-lg-4">
      <div class="cta-card">
        <div class="text-start">
          <h4 class="mb-3 fw-bold intro-title">Revolutionizing Workforce with AI</h4>
          <p class="mb-4 into-description">Unlock the power of AI to align skills, enhance learning, and optimize recruitment, driving performance and aligning with your organization's goals</p>
          <div class="d-grid">
           
          </div>
          
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="row g-4">
        <div class="col-md-6">
          <div class="card-custom border-gradient">
            <div class="d-flex align-items-center mb-3">
              <img src="@/assets/images/feature-icon.png" alt="Google logo" class="provider-logo">
              <h6 class="mb-0 ms-3">Workforce skills alignment</h6>
            </div>
            <ul class="feature-points">
              <li>Unlock the power of AI to align skills, enhance learning, and optimize recruitment, driving performance and aligning with your organization's goals</li>
              <li>Foster continuous growth with personalized skill development driven by AI.</li>
              <li>Monitor skill progress and report on development to ensure optimal performance.</li>
            </ul>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="badge bg-soft-primary">New</span>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card-custom border-gradient">
            <div class="d-flex align-items-center mb-3">
              <img src="@/assets/images/feature-icon-two.png" alt="Michigan logo" class="provider-logo">
              <h6 class="mb-0 ms-3">AI Skills Auditing</h6>
            </div>
            <ul class="feature-points">
              <li>Evaluate workforce skills through AI-driven adaptive assessments.</li>
              <li>Identify and address skill gaps in real time to enhance workforce efficiency.</li>
              <li>Leverage AI analytics to generate actionable insights for skill improvement.</li>
            </ul>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="badge bg-soft-primary">New</span>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card-custom border-gradient">
            <div class="d-flex align-items-center mb-3">
              <img src="@/assets/images/feature-icon-three.png" alt="Vanderbilt logo" class="provider-logo">
              <h6 class="mb-0 ms-3">Personalized Learning</h6>
            </div>
            <ul class="feature-points">
              <li> AI tracks your learning journey, identifying struggles and strengths to personalize content delivery.</li>
              <li>AI is always updated with the latest curriculum to provide current and relevant learning materials.</li>
              <li>Enhance retention with flashcards that adapt to your learning progress.</li>
            </ul>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="badge bg-soft-primary">New</span>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card-custom border-gradient">
            <div class="d-flex align-items-center mb-3">
              <img src="@/assets/images/feature-icon-four.png" alt="Coursera logo" class="provider-logo">
              <h6 class="mb-0 ms-3">Cut reliance on agents</h6>
            </div>
            <ul class="feature-points">
              <li>Automate candidate sourcing, screening, and shortlisting with AI-driven tools.</li>
              <li>Minimize recruitment agency fees by leveraging AI for in-house hiring processes.</li>
              <li>AI analyzes resumes and job descriptions to match the best-fit candidates faster and more accurately.</li>
            </ul>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="badge bg-soft-primary">New</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="  ">
  <br><br>
  <h2 class=" mb-3 fw-bold" id="features_section">Orcaa.ai Suite Features</h2>
  <br>

  <div class="usecase-scroll" >
   <!-- Recruitment Feature Card -->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/recruitment-icons.svg" >
        <br>
        <h4 class="fw-bold text-center mt-2">Recruitment</h4>  
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customizable Recruitment Criteria
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Tailor your hiring standards with precision to align candidates perfectly with your organizational needs.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Skills & Fit Mapping Criteria
                    </div>
                </div>
                <div class="feature-description">
                  Define precise skill requirements and cultural-fit benchmarks to systematically evaluate candidate alignment with your organizational needs.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Clear Candidate Status
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Your set criteria automatically sort applicants into simple statuses like "Perfect Match," "Maybe," and "Not a Fit" to speed up hiring.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Automated CV-to-Job Matching
                  </div>
              </div>
              <div class="feature-description">
                The system scans CVs and automatically checks them against your defined job requirements, showing how well each candidate fits.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Controlled Automation for Recruitment
                </div>
            </div>
            <div class="feature-description">
              Our system automates the repetitive early stages (CV screening, skills matching, and candidate shortlisting) while keeping final hiring decisions under your control.
            </div>
        </li> 
        <li class="feature-item">
          <div class="feature-header">
              <i class='bx bx-chevron-right'></i>
              <div class="feature-text">
                Automated Contract Signing & Data Integration
              </div>
          </div>
          <div class="feature-description">
            Enable candidates to sign contracts digitally, then automatically transfer finalized documents and candidate data to your in-house "last mile" platform—eliminating manual entry and ensuring seamless onboarding.
          </div>
      </li> 
 
        </ul> 
      </div>
    </div> 

     <!--  Recruitment Feature Card-->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/skills-auditing-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Skills Auditing</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Automated Skills-Based Candidate Matching
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    The system automatically evaluates candidates' skills against your defined requirements, scoring and ranking applicants based on their qualifications to identify top talent efficiently. 
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Survey Creation & Management
                    </div>
                </div>
                <div class="feature-description">
                  Design, distribute, and analyze custom surveys to gather candidate feedback, measure employee engagement, or assess skills—all within a centralized platform with real-time analytics.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Integrations & Data Analysis
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Leverage AI-driven integrations to automate data collection, generate predictive insights, and optimize decision-making across recruitment, employee engagement, and performance metrics.              </p>
            </div>
          </li>
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Time-Stamped Data Collection, Storage & Integration
                </div>
            </div>
            <div class="feature-description">
              <p> 
                Capture, centralize, and synchronize recruitment and workforce data with audit-ready timestamps—enabling real-time dashboards that track trends, compliance, and decision timelines.        </p>
          </div>
        </li> 
        <li class="feature-item">
          <div class="feature-header">
              <i class='bx bx-chevron-right'></i>
              <div class="feature-text">
                AI-Powered Open-Ended Response Scoring
              </div>
          </div>
          <div class="feature-description">
            <p> 
              Our AI analyzes open-ended candidate/employee responses (essays, interview answers, project reflections) to objectively evaluate skill proficiency levels using natural language processing and benchmarking against defined competency frameworks</p>
        </div>
      </li>  
        </ul> 
      </div>
    </div> 

    <!--  Learning & Training Feature Card -->
    <div class="usecase-card ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/learning-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Sills development and Learning</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      AI-Generated Personalized Upskilling Programs
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    The system auto-generates customized learning paths (courses, simulations, micro-trainings) to bridge skill gaps between an employee’s current abilities and their role requirements—delivered via a dynamic, feedback-driven platform. 
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Precision Upskilling Engine    </div>
                </div>
                <div class="feature-description">
                  Using verified skill audit data, the system auto-builds hyper-targeted training programs that advance employees from their current proficiency (e.g., "Python: L2") to their role-required level ("Python: L4") in the shortest effective path.
                </div>
            </li>  
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Competency Activation Engine    
                    </div>
                </div>
                <div class="feature-description">
                  The system generates real-work tasks tailored to an employee's target skill level, ensuring they prove mastery through practical application—not just theory.             
                </div>
            </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  AI-Powered Tutoring & Support
                </div>
            </div>
            <div class="feature-description">
              A virtual coach that diagnoses skill deficiencies in real-time and delivers bite-sized, role-specific training with instant feedback—scaling expertise across your organization.
            </div>
        </li> 
        <li class="feature-item">
          <div class="feature-header">
              <i class='bx bx-chevron-right'></i>
              <div class="feature-text">
                AI Task Tracking & Completion Reporting
              </div>
          </div>
          <div class="feature-description">
            Automatically tracks skill-building task progress, validates outputs, and generates auditable reports—giving L&D and managers visibility into proven competency development.
          </div>
      </li>
      <li class="feature-item">
        <div class="feature-header">
            <i class='bx bx-chevron-right'></i>
            <div class="feature-text">
              AI-Driven Course & Assessment Assignment
            </div>
        </div>
        <div class="feature-description">
          Automatically assigns the right training and tests to the right people at the right time.
        </div>
    </li>
    <li class="feature-item">
      <div class="feature-header">
          <i class='bx bx-chevron-right'></i>
          <div class="feature-text">
            AI-Optimized KPI Tracking & Smart Interventions
          </div>
      </div>
      <div class="feature-description">
        Our AI-powered platform continuously tracks skill development KPIs (completion rates, assessment scores, applied competency) and automatically triggers targeted interventions—from micro-lessons to mentor matching—when performance deviations occur. The system learns which corrective actions work best for specific skill gaps, teams, and individual learning styles, creating a self-optimizing cycle of measurable improvement.
      </div>
  </li>
 
        </ul> 
      </div>
    </div> 

    <div class="usecase-card  ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2 text-center" src="@/assets/images/assessment-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Program and Strategic Management</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Program Strategy Template                   
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    A program strategy template defines the control and flow of activities, guiding the overall direction and execution of the program.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Skill Level in Program Strategy            
                    </div>
                </div>
                <div class="feature-description">
                  Defines the expertise required for tasks, ensuring the right talent is assigned for successful program execution.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Program Budget
                  </div>
              </div>
              <div class="feature-description"> 
                A program budget outlines the financial resources allocated for each activity, ensuring proper funding and financial control throughout the program.      
              </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Program Timelines        
                  </div>
              </div>
              <div class="feature-description">
                Program timelines define the schedule for each activity, ensuring tasks are completed on time and the program progresses as planned.
              </div>
          </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Skills Assessment            
                </div>
            </div>
            <div class="feature-description">
              Easily integrate data from existing Learning Management Systems (LMS) to maintain continuity.
            </div>
          </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Strategic Measure              
                </div>
            </div>
            <div class="feature-description">
              A strategic measure tracks the progress and success of a program, ensuring alignment with goals and providing insights for necessary adjustments.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Talent Pipeline Strength            
                </div>
            </div>
            <div class="feature-description">
              Talent pipeline strength evaluates the availability and quality of potential candidates, ensuring a steady flow of skilled individuals for program needs.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Talent Pipeline Strength            
                </div>
            </div>
            <div class="feature-description">
              Talent pipeline strength evaluates the availability and quality of potential candidates, ensuring a steady flow of skilled individuals for program needs.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Return on Investment (ROI)   
                </div>
            </div>
            <div class="feature-description">
              ROI measures the financial gain or loss from a program relative to its cost, helping evaluate the program's overall effectiveness and value.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Employee Retention and Growth
                </div>
            </div>
            <div class="feature-description">
              Employee retention and growth focus on maintaining a skilled workforce while fostering professional development, ensuring long-term success and stability within the program.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Position Management
                </div>
            </div>
            <div class="feature-description">
              Position management involves organizing and defining roles within a program, ensuring the right positions are filled with the appropriate skills to meet organizational goals.
            </div>
          </li>
  
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Trigger Skills Auditing
                </div>
            </div>
            <div class="feature-description">
              Trigger skills auditing evaluates the skills of employees based on program needs or performance milestones, ensuring alignment with objectives and identifying areas for development.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Key Performance Indicator (KPI)
                </div>
            </div>
            <div class="feature-description">
              KPIs are measurable values used to track the success of a program, ensuring that progress aligns with set objectives and helping guide decision-making.
            </div>
          </li>

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Placement Ecosystem
                </div>
            </div>
            <div class="feature-description">
              The placement ecosystem refers to the network of processes, resources, and partnerships that facilitate the placement of talent into suitable roles, ensuring a smooth transition and alignment with organizational needs.
            </div>
          </li>
  
        </ul> 
      </div>
    </div>  

  </div> 
  <br>
  <h2 class="mt-3 mb-3 fw-bold" id="more_features_section">More Features</h2>
  <br>
  <div class="usecase-scroll" > 
    <!--  KPI & Interventions Feature Car-->
    <div class="usecase-card   ">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/intervention-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Learning Management System</h4> 
        <ul class="feature-list text-start">
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Courses, Quizzes, Tests, and Exams
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  This refers to the structured learning and assessment tools used to evaluate knowledge and skills, helping track progress and mastery of course material.                  
                </p>
              </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI Marking                  
                  </div>
              </div>
              <div class="feature-description">
                AI marking uses artificial intelligence to automate the assessment and grading of assignments, quizzes, and exams, ensuring consistency and efficiency in evaluating student performance.             
              </div>
          </li>  

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  AI-Assisted Personalized Testing
                </div>
            </div>
            <div class="feature-description"> 
              AI-assisted personalized testing tailors assessments to individual learning needs, adapting questions based on performance to optimize learning outcomes and accuracy.     
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Interactive Learning Style             
                  </div>
              </div>
              <div class="feature-description">
                This learning style emphasizes hands-on coding exercises, where learners build projects and solve challenges, gaining practical experience through active participation.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Scorefile
                 </div>
            </div>
            <div class="feature-description">
              A scorefile tracks and stores performance results, such as quiz scores or exam grades, providing a record of progress and areas for improvement.
            </div>
          </li>
        </ul> 
        <div class="featured-addons">
            
        </div>
      </div>
    </div> 

    <!--  Marketplace Feature Card -->
    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/market-icon.svg" >

        <h4 class="fw-bold text-center mt-2">AI Tutor</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Easily Digestible Learning                
                      </div>
                </div>
                <div class="feature-description"> 
                  Easily digestible learning breaks down complex topics into simple, manageable chunks, making it easier for learners to understand and retain information.          
                </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Flashcards Texts              
                    </div>
                </div>
                <div class="feature-description">
                  Flashcard texts are concise, focused pieces of information used for quick review and memorization, helping learners retain key concepts through repetition and active recall.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Flashcards with Images
                  </div>
              </div>
              <div class="feature-description"> 
                Flashcards with images combine visual aids with text to enhance learning, making it easier for learners to associate concepts with relevant visuals for better retention and understanding.          
              </div>
          </li>
          
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Flashcards with Images
                </div>
            </div>
            <div class="feature-description"> 
              Flashcards with images combine visual aids with text to enhance learning, making it easier for learners to associate concepts with relevant visuals for better retention and understanding.          
            </div>
        </li>

        <li class="feature-item">
          <div class="feature-header">
              <i class='bx bx-chevron-right'></i>
              <div class="feature-text">
                Flashcards with Video
              </div>
          </div>
          <div class="feature-description"> 
            Flashcards with video incorporate short video clips to explain concepts, offering dynamic and engaging content that enhances comprehension and retention through visual and auditory learning.         
          </div>
      </li>
      <li class="feature-item">
        <div class="feature-header">
            <i class='bx bx-chevron-right'></i>
            <div class="feature-text">
              Quizzes Based on Flashcards
            </div>
        </div>
        <div class="feature-description"> 
          Quizzes based on flashcards use the key concepts and information from flashcards to test knowledge, reinforcing learning through active recall and reinforcing retention.         
        </div>
    </li>

    <li class="feature-item">
      <div class="feature-header">
          <i class='bx bx-chevron-right'></i>
          <div class="feature-text">
            Exam Questions Practice
          </div>
      </div>
      <div class="feature-description"> 
        Exam questions practice involves solving past or sample questions to familiarize learners with the format and types of questions, helping improve performance and confidence during exams.         
      </div>
  </li>

  <li class="feature-item">
    <div class="feature-header">
        <i class='bx bx-chevron-right'></i>
        <div class="feature-text">
          Learning and Exam Optimized
        </div>
    </div>
    <div class="feature-description"> 
      Learning and exam optimized approaches focus on strategies and tools that enhance both study effectiveness and exam performance, ensuring better retention, understanding, and test-taking skills.        
    </div>
</li>

<li class="feature-item">
  <div class="feature-header">
      <i class='bx bx-chevron-right'></i>
      <div class="feature-text">
        Creating Rich Content from Minimal Context
      </div>
  </div>
  <div class="feature-description"> 
    This ability involves generating detailed and engaging content using limited information, focusing on extracting key insights and expanding them into comprehensive and informative material.        
  </div>
</li>
  
<li class="feature-item">
  <div class="feature-header">
      <i class='bx bx-chevron-right'></i>
      <div class="feature-text">
        Integration of a Human Tutor
      </div>
  </div>
  <div class="feature-description"> 
    The integration of a human tutor enhances learning by providing personalized guidance, feedback, and support, complementing automated systems and ensuring tailored assistance for each learner.        
  </div>
</li>
        </ul>  

        
        <div class="featured-addons">
            
        </div>
      </div>
    </div> 
    

    <div class="usecase-card">
      <div class="feature-card bg-white">
        <img class="img-fluid h-70 mb-2" src="@/assets/images/market-icon.svg" >

        <h4 class="fw-bold text-center mt-2">Operation Reference Point</h4> 
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Optimized for Content from Verifiable Sources               
                      </div>
                </div>
                <div class="feature-description"> 
                  This approach focuses on sourcing content from reliable, verifiable sources to answer user questions, ensuring accurate and trustworthy information for informed decision-making.          
                </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Connect to Proba Warehousing for Enterprise Customers           
                    </div>
                </div>
                <div class="feature-description">
                  This involves integrating with Proba Warehousing to securely store and manage enterprise customer data, ensuring efficient access, retrieval, and analysis of data for business insights and decision-making.
                </div>
            </li>
        </ul>  
        <div class="featured-addons">
            
        </div>
      </div>
    </div> 

  
  </div>  
</div>
 