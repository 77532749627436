<div class="footer-background">
    <div class="space"></div>
    <footer class="footer-content text-white pt-5 pb-4">
        <div class="container text-center text-md-left">
            <div class="row text-center text-md-left">
                <div class="col-md-3 col-lg-3 col-xl-3 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Orcaa.ai Suites</h5>
                    <br>
                    <a href="https://assist.orcaa.ai/"class="text-start d-block text-white text-decoration-none mb-4" target="_blank">Orcaa.ai Assist</a>
                    <a href="#" class="text-start d-block text-muted text-decoration-none mb-4">Orcaa.ai Plus</a>
                    <a href="#" class="text-start d-block text-muted text-decoration-none mb-4">Orcaa.ai X</a>
                    <a href="#" class="text-start d-block text-muted text-decoration-none mb-4">Orcaa.ai Internship</a>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Get in touch</h5>
                    <br>
                    <a href="https://www.google.com/maps/search/?api=1&query=Ground+Floor,+35+Ferguson+Road,+Illovo,+Sandton,+Gauteng,+2196" class="text-start d-block text-white text-decoration-none mb-4" target = "_blank">Ground Floor, 35 Ferguson Road, Illovo, Sandton, Gauteng, 2196</a>
                    <a href="tel:+27110841100" class="text-start d-block text-white text-decoration-none mb-4">+27 11 084 1100</a>
                    <a href="mailto:marketing@epftechhubic.org" class="text-start d-block text-white text-decoration-none mb-4">Email Us</a>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto">
                    <h5 class="text-start font-weight-bold text-white">Get in touch</h5>
                    <br>
                    <a href="#" class="text-start d-block text-white text-decoration-none mb-4">About us</a>
                    <a href="#" class="text-start d-block text-white text-decoration-none mb-4">Careers</a>
                    <a href="#" class="text-start d-block text-white text-decoration-none mb-4">Press</a>
                    <a href="#" class="text-start d-block text-white text-decoration-none mb-4">Partnership</a>
                </div>
            </div>
            <hr class="mb-2">
            <div class="row align-items-center">
                <div class="col-md-7 col-lg-8 text-end">
                    <p>Copyright ©2024 All rights reserved by EPF Innovation Campus</p>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="text-center text-md-right">
                        <ul class="list-unstyled list-inline text-end">
                            <li class="list-inline-item">
                                 <a href="https://www.tiktok.com/@artificialcampus?is_from_webapp=1&sender_device=pc" target="_blank" class="btn-floating btn-sm text-white">
                                <box-icon name='tiktok' type='logo' color='#ffffff'></box-icon>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.instagram.com/epf_innovationcampus/?hl=en" target="_blank" class="btn-floating btn-sm text-white">
                                <box-icon name='instagram' type='logo' color='#ffffff'></box-icon>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.youtube.com/watch?v=21RLbM8z4_Y" target="_blank" class="btn-floating btn-sm text-white">
                                <box-icon name='youtube' type='logo' color='#ffffff'></box-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
