<div class="app-content">
  <div class=" "><Main_home /></div>
  <div class="flex-1 bg-feature px-3" id="feature-setion"><Features_page /></div>
  <div class="flex-1"><About_page /></div>
  <div class="flex-1 bg-light-purple"><Home /></div>
  <div class="flex-1 bg-sector"><Sectors_page /></div>
  <div class="flex-1 d-none"><Statistics_page /></div>
  <section id="products-section">
    <div class="flex-1 d-none"><Corporate_new_page /></div> 
  </section>
  <div class="flex-1" id="event-page"><Event_landing_page/></div>
  <div class="flex-1 bg-light-grey p-40" id="payment-page"><Payment_page /></div>
  <div class="flex-1" id="contact-us"><Contact_us_page /></div>
  <div class="flex-1"><Footer_page /></div>
</div>
