 
<div class="container  " id="pricelist">
  <br>
  <h1 class="  mb-3 fw-bold">Pick the plan that's right for you.</h1>
  <p class="  mb-3 fw-normal">Get started today.</p>

  <br>
  <div class="container">
    <div class="row g-4 justify-content-center">
      <!-- Orcaa assist-->
      <div class="col-12 col-md-6 col-lg-4">
          <div class="pricing-card">
              <div class="card-header">
                  <div class="gb-amount"><span class="fw-normal">Orcaa</span> Assist <span class="fw-normal text-xxs">Basic</span></div>
              </div>
              <div class="card-content"> 
                  <div class="orcaa-price">
                    <span class="text-xxs  fw-normal bg-grey p-1 text-dark rounded-pill">Learners</span> <br>
                    R99.99<span class="text-xxs text-muted fw-normal">/month</span>  </div>
                  <div class="free-connection fw-normal">per seat </div> 
                  <hr>
                  <div class="orcaa-price">R49.99<span class="text-xxs  text-muted fw-normal">/month</span> </div>
                  <div class="free-connection fw-normal">Additional child per seat </div> 
                  <hr>

                  <ul class="feature-list text-start">
                    <li class="feature-item">
                        <div class="feature-header">
                            
                            <div class="feature-text">
                            Real-Time Academic Updates
                            </div>
                        </div>
                        <div class="feature-description">
                          <p> 
                          Parents receive instant updates on their children’s academic progress and achievements, helping them stay informed. 
                          </p>
                      </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header"> 
                            <div class="feature-text">
                            Integrated Behavioural Insights
                            </div>
                        </div>
                        <div class="feature-description">
                        Track behavioural patterns to provide holistic support for student development.
                      </div>
                    </li>  
        
                    <li class="feature-item">
                      <div class="feature-header"> 
                          <div class="feature-text">
                          Scheduled Progress Report                     
                          </div>
                      </div>
                      <div class="feature-description">
                        <p> 
                        Parents can set up recurring course progress reports to stay updated on their learner’s growth.                     
                      </p>
                    </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header"> 
                            <div class="feature-text">
                            Parent-Purchased Intervention Courses
                            </div>
                        </div>
                        <div class="feature-description">
                        Parents can purchase extra courses as part of intervention support, allowing tailored academic assistance.
                      </div>
                    </li> 
          
                  </ul>  
                  <a class="order-btn text-decoration-none btn" href="https://assist.orcaa.ai/" target="_blank">Start Trial</a> 
              </div>
          </div>
      </div>

      <!-- orcaa Internship -->
      <div class="col-12 col-md-6 col-lg-4 d-none">
          <div class="pricing-card recommended">
              <div class="recommendation-badge">
                  <i class="fas fa-thumbs-up"></i>
              </div>
              <div class="card-header">
                  <div class="gb-amount"><span class="fw-normal">Orcaa</span> Internship <span class="fw-normal text-xxs">Basic</span></div>
              </div>
              <div class="card-content">

                <div class="orcaa-price"> 
                  <span class="text-xxs  fw-normal bg-grey p-1 text-dark rounded-pill">
                    Recruitment Platform</span> <br>R2999.99
                    <span class="text-xxs text-muted fw-normal">/month</span>  
                    <div class="free-connection fw-normal">For each learner </div> 
                </div>
                <hr> 
                
                <div class="orcaa-price"> 
                  <span class="text-xxs  fw-normal bg-grey p-1 text-dark rounded-pill">
                    Mentors</span> <br>R99.99<span class="text-xxs text-muted fw-normal">/month</span> 
                  <div class="free-connection fw-normal">Amount receive per learner </div> 
                </div>
                <hr>  

                <ul class="feature-list text-start">
                  <li class="feature-item">
                      <div class="feature-header"> 
                          <div class="feature-text">
                            Advertise Internship Opportunities
                          </div>
                      </div>
                      <div class="feature-description">
                        <p> 
                          Promote internships effectively across channels.
                        </p>
                    </div>
                  </li>
                  <li class="feature-item">
                      <div class="feature-header"> 
                          <div class="feature-text">
                            Customisable Onboarding Workflow
                          </div>
                      </div>
                      <div class="feature-description">
                        Define processes to suit organisational needs.
                      </div>
                  </li>  
      
                  <li class="feature-item">
                    <div class="feature-header"> 
                        <div class="feature-text">
                          AI-Powered Adaptive Assessments
                        </div>
                    </div>
                    <div class="feature-description">
                      <p> 
                        Assess intern skills accurately.
                      </p>
                  </div>
                  </li>
                  <li class="feature-item">
                      <div class="feature-header"> 
                          <div class="feature-text">
                            Automated and Scheduled Assessments
                          </div>
                      </div>
                      <div class="feature-description">
                        Monitor intern progress over time.
                      </div>
                  </li> 
                  <li class="feature-item">
                    <div class="feature-header"> 
                        <div class="feature-text">
                          AI-Powered Marking and Feedback.
                        </div>
                    </div>
                    <div class="feature-description">
                      Provide efficient grading and constructive feedback.
                    </div>
                  </li> 
                  <li class="feature-item">
                    <div class="feature-header"> 
                        <div class="feature-text">
                          Generate Reporting on Defined Skills
                        </div>
                    </div>
                    <div class="feature-description">
                      Keep track of intern development.
                    </div>
                  </li> 
    
                </ul>  
                <button class="order-btn">Start Trial</button> 
            </div>
          </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="pricing-card recommended">
            <div class="recommendation-badge">
                <i class="fas fa-thumbs-up"></i>
            </div>
            <div class="card-header">
                <div class="gb-amount"><span class="fw-normal">Orcaa</span> Internship <span class="fw-normal text-xxs">Business</span></div>
            </div>
            <div class="card-content"> 
              <div class="orcaa-price"> 
                <span class="text-xxs  fw-normal bg-grey p-1 text-dark rounded-pill">
                 Recruitment</span> <br>R2 999.99
                  <span class="text-xxs text-muted fw-normal">/month</span>  
                  <div class="free-connection fw-normal d-none">For each student</div> 
              </div>
              <hr>  
              <div class="orcaa-price"> 
                <span class="text-xxs  fw-normal bg-grey p-1 text-dark rounded-pill ">
                   Management of Learners</span> <br> R59.99
                  <span class="text-xxs text-muted fw-normal">/month</span>  
                  <div class="free-connection fw-normal"> per learner</div> 

              </div>
              <hr> 
              <button class="order-btn">Start Trial</button>  
              <div class="orcaa-price fs-4"> 
                Big & Large Corporates  
                <div class="free-connection fw-normal">For more info</div> 
              </div>
              <button 
              class="order-btn bg-transparent border text-dark"
              @click="scrollToContact('Orcaa X Corporate')"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div> 

      <!-- Orcaa Orcaa X-->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="pricing-card">
            <div class="card-header">
                <div class="gb-amount"><span class="fw-normal">Orcaa</span> X <span class="fw-normal text-xxs">Corporate</span></div>
            </div>
            <div class="card-content">
                  <div class="orcaa-price">Business Plan<span class="text-xxs text-muted fw-normal"> </span> 
                   </div>
                  <ul class="feature-list text-start">
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Define Employee Training Skills
                            </div>
                        </div>
                        <div class="feature-description">
                          <p> 
                            Identify and outline specific skills required for employee development programs.
                          </p>
                      </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Link Training to Company KPIs
                            </div>
                        </div>
                        <div class="feature-description">
                          Align training programs with key performance indicators.
                        </div>
                    </li>  
        
                    <li class="feature-item">
                      <div class="feature-header">
                         
                          <div class="feature-text">
                            AI-Powered Adaptive Assessments                    
                          </div>
                      </div>
                      <div class="feature-description">
                        <p> 
                          Implement intelligent assessments that adapt to employee progress.
                        </p>
                      </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Customisable Skills Auditing Workflow
                            </div>
                        </div>
                        <div class="feature-description">
                          Tailor workflows to align skills auditing with your company’s strategic goals.
                        </div>
                    </li> 
        
                    <li class="feature-item">
                      <div class="feature-header">
                         
                          <div class="feature-text">
                            Host Paid or Free Training Modules
                          </div>
                      </div>
                      <div class="feature-description">
                        Provide flexibility in offering training content to meet diverse employee development needs.
                      </div>
                    </li> 
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Automated and Scheduled Assessments
                            </div>
                        </div>
                        <div class="feature-description">
                          Set up assessments that adapt to each employee’s progress.
                        </div>
                    </li> 
          
                  </ul> 
                  <button 
                  class="order-btn bg-transparent border text-dark"
                  @click="scrollToContact('contact-form')"
                >
                  Contact Us
                </button>
            </div>
        </div>
    </div>


    </div>

    <div class="row g-4 justify-content-center mt-2">
      <!-- Orcaa Plus-->
      <div class="col-12 col-md-6 col-lg-4 d-none">
          <div class="pricing-card">
              <div class="card-header">
                  <div class="gb-amount"><span class="fw-normal">Orcaa</span> Plus <span class="fw-normal text-xxs">Basic</span></div>
              </div>
              <div class="card-content">
                    <div class="orcaa-price">TBA<span class="text-xxs text-muted fw-normal"> </span> 
                     </div>
                    
                  <ul class="feature-list text-start">
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Define Learning Skills
                            </div>
                        </div>
                        <div class="feature-description">
                          <p> 
                            Identify and outline specific skills required for educational programs.
                          </p>
                      </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Customisable Skills Auditing Workflow
                            </div>
                        </div>
                        <div class="feature-description">
                          Create workflows that align with institutional requirements.
                        </div>
                    </li>  

                    <li class="feature-item">
                      <div class="feature-header">
                         
                          <div class="feature-text">
                            Audit of Accredited Courses                    
                          </div>
                      </div>
                      <div class="feature-description">
                        <p> 
                          Evaluate and verify course quality and accreditation.
                        </p>
                    </div>
                    </li>
                    <li class="feature-item">
                        <div class="feature-header">
                           
                            <div class="feature-text">
                              Plagiarism Checker
                            </div>
                        </div>
                        <div class="feature-description">
                          Ensure originality in student submissions.
                        </div>
                    </li> 
                    <li class="feature-item">
                      <div class="feature-header">
                         
                          <div class="feature-text">
                            Skills-Audited Certificates                  
                          </div>
                      </div>
                      <div class="feature-description">
                        Issue certificates validating student achievements.
                      </div>
                    </li> 

                  </ul> 
                  <button class="order-btn d-none">Start Trial</button> 
              </div>
          </div>
      </div>

   
    </div>


  </div> 
  <br><br>

</div> 