<div>
  <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-block">
    <div class="container-fluid">
        <!-- Logo and Company Name -->
        <a class="navbar-brand d-flex align-items-center" href="#">
      <img src="@/assets/images/orcaa.svg" alt="Orcaa.ai Logo" width="30" height="30" class="d-inline-block align-text-top me-2">
      Orcaa.ai
    </a>
    
        
        <!-- Navbar Links -->
        <div class="collapse navbar-collapse justify-content-end" id="navbarContent">
            <ul class="navbar-nav">
                <!-- Home Link -->
                <li class="nav-item">
                    <a class="nav-link" href="#">Home</a>
                </li>
                
                <!-- Dropdown 1 -->
                <li class="nav-item dropdown">
                    <a 
                        class="nav-link dropdown-toggle" 
                        href="#" 
                        id="navbarDropdown1" 
                        role="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                    >
                        Features
                    </a>
                    <ul 
                        class="dropdown-menu dropdown-menu-end" 
                        aria-labelledby="navbarDropdown1"
                    >
                        <li><a class="dropdown-item" href="#feature-setion">Recruitment</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">Skills Auditing</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">Skills Development and Learning</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">Program and Strategic Management</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">Learning Management System</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">AI Tutor</a></li>
                        <li><a class="dropdown-item" href="#feature-setion">Operation Reference Point</a></li>
                    </ul>
                </li>
                
                <!-- Dropdown 2 -->
                <li class="nav-item dropdown">
                    <a 
                        class="nav-link dropdown-toggle" 
                        href="#" 
                        id="navbarDropdown2" 
                        role="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                    >
                        Pricing
                    </a>
                    <ul 
                        class="dropdown-menu dropdown-menu-end" 
                        aria-labelledby="navbarDropdown2"
                    >
                        <li><a class="dropdown-item" href="#payment-page">Orcaa Corporate</a></li>
                        <li><a class="dropdown-item" href="#payment-page">Orcaa Internship</a></li>
                        <li><a class="dropdown-item" href="#payment-page">Orcaa Plus</a></li>
                        <li><a class="dropdown-item" href="#payment-page">Orcaa Assist</a></li>
                    </ul>
                </li>
                
                <!-- About Link -->
                <li class="nav-item">
                    <a class="nav-link" href="#event-page">Open day</a>
                </li>

                <!-- Contact Us Button for Desktop -->
                <li class="nav-item">
                    <a href="#contact-us" class="btn contact-us ms-2">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!-- Mobile Navigation (sm and down) -->
<nav class="navbar navbar-expand-lg navbar-light bg-light d-lg-none">
  <div class="container-fluid">
    <!-- Logo and Company Name -->
    <a class="navbar-brand d-flex align-items-center" href="#">
      <img src="@/assets/images/orcaa.svg" alt="Company Logo" width="30" height="30" class="d-inline-block align-text-top me-2">
      Orcaa.ai
    </a>

    <!-- Mobile Menu Button -->
    <button 
      class="navbar-toggler" 
      type="button" 
      @click="isNavbarCollapsed = !isNavbarCollapsed"
      aria-controls="mobileNavbar" 
      aria-expanded="false" 
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collapsible Navigation Content -->
    <div :class="['collapse navbar-collapse', {'show': !isNavbarCollapsed}]" id="mobileNavbar">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <!-- Home Link -->
        <li class="nav-item">
          <a class="nav-link" href="#">Home</a>
        </li>

        <!-- Products Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" 
             href="#" 
             role="button" 
             @click="$event.preventDefault(); $event.target.parentNode.classList.toggle('show'); $event.target.nextElementSibling.classList.toggle('show');"
             aria-expanded="false">
            Features
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Recruitment</a></li>
            <li><a class="dropdown-item" href="#">Skills Auditing</a></li>
            <li><a class="dropdown-item" href="#">Skills Development and Learning</a></li>
            <li><a class="dropdown-item" href="#">Program and Strategic Management</a></li>
            <li><a class="dropdown-item" href="#">Learning Management System</a></li>
            <li><a class="dropdown-item" href="#">Operation Reference Point</a></li>
          </ul>
        </li>

        <!-- Services Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" 
             href="#" 
             role="button" 
             @click="$event.preventDefault(); $event.target.parentNode.classList.toggle('show'); $event.target.nextElementSibling.classList.toggle('show');"
             aria-expanded="false">
            Pricing
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Orcaa Corporate</a></li>
            <li><a class="dropdown-item" href="#">Orcaa Internship</a></li>
            <li><a class="dropdown-item" href="#">Orcaa Plus</a></li>
            <li><a class="dropdown-item" href="#">Orcaa Assist</a></li>
          </ul>
        </li>

        <!-- About Link -->
        <li class="nav-item">
          <a class="nav-link" href="#">About</a>
        </li>
      </ul>
      
      <!-- Contact Us Button -->
      <div class="d-grid gap-2 mt-3">
        <a href="#" class="btn contact-us">Contact Us</a>
      </div>
    </div>
  </div>
</nav>

</div>

  <div class="main-background">
    <section class="hero-section    ">
      <div class="hero">
        <div class="row">

          <div class="col-md-6 col-sm-12 align-content-center">
          
            <div class="hero-content text-start w-100">
              <h1 class="text-md fw-bold">Your Transparent Knowledge and Skills Evaluation Platform</h1>
              <p >
                 Choose Your Perfect <b>Orcaa.ai Suite</b> Today!
              </p>
              <a href="#payment-page" class="cta-button text-decoration-none">Book a Demo</a>
            </div>
          </div>

          <div class="col-md-6 col-sm-12">
            <div class="hero-image col-6-md col-sm-12">
              <div class="purple-circle" ref="purpleCircle"></div>
              <img src="@/assets/images/orcaa-orcaa-mockup.png" id="hero-shoe" ref="heroSuite" class="img-fluid">
            </div> 
          </div>

        </div>
      </div>
  
      <div class="cards-container">
        <button class="scroll-btn prev-btn" ref="prevBtn" @click="scrollCards('prev')">←</button>
        <div class="cards-wrapper p-5" ref="cardsWrapper" @scroll="updateScrollButtons">
          <div 
            class="card" 
            v-for="(card, index) in cards" 
            :key="index"
            @mouseover="hoverCard(card)" 
            @mouseleave="resetHover"
            @click="clickCard(card)"
            :class="{ 'active': activeImage === card.image }"
          >
            <img :src="card.image">
            <h5 class="text-start fw-normal">{{ card.title }}</h5>
            <p class="text-start">{{ card.suite }}</p>
          </div>
        </div>
        <button class="scroll-btn next-btn" ref="nextBtn" @click="scrollCards('next')">→</button>
      </div>

    </section>
  </div> 
  
  