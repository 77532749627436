export default {
    data() {
      return {
        name: '',
        email: '',
        subject: '',
        message: '',
        isSubmitting: false
      }
    },
    methods: {
      async submitForm(event) {
        // Prevent default form submission
        event.preventDefault()
        
        // Prevent multiple submissions
        if (this.isSubmitting) return
        
        this.isSubmitting = true
        
        // Prepare template parameters
        const templateParams = {
          Name: this.name,
          Email: this.email,
          Subject: this.subject,
          Message: this.message,
          date: new Date().toLocaleString()
        }
        
        try {
          // Send email using EmailJS
          const response = await emailjs.send(
            'service_i5h4bqn',  // Replace with your EmailJS Service ID
            'template_f0837zz', // Replace with your EmailJS Template ID
            templateParams
          )
          
          // Success alert
          Swal.fire({
            icon: 'success',
            title: 'Message Sent!',
            text: 'Your message has been successfully sent.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
          
          // Reset form
          this.name = ''
          this.email = ''
          this.subject = ''
          this.message = ''
        } catch (error) {
          // Error alert
          Swal.fire({
            icon: 'error',
            title: 'Sending Failed',
            text: 'Unable to send message. Please try again later.',
            footer: `Error: ${error.message}`
          })
        } finally {
          this.isSubmitting = false
        }
      }
    },
    mounted() {
      // Initialize EmailJS (replace with your User ID)
      emailjs.init('Cd7FVv44hvESdGFvM')
      
      // Find the form and attach Vue submit handler
      const form = document.getElementById('contact-form')
      if (form) {
        form.addEventListener('submit', this.submitForm)
      }
    },
    beforeUnmount() {
      // Remove event listener to prevent memory leaks
      const form = document.getElementById('contact-form')
      if (form) {
        form.removeEventListener('submit', this.submitForm)
      }
    }
  }