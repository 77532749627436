<section class="cta-container">
  <div v-if="!showForm" class="cta-content">
    <h2 class="cta-title fw-bold">Orcaa.ai Open Day</h2>
    <p class="cta-subtitle">Exploring AI in Education, Training & Workforce Development</p>
    
    <!-- Countdown Timer -->
    <div class="countdown-container mb-3">
      <h3 class="countdown-heading">Event Starts In:</h3>
      <div class="countdown-timer">
        <div class="countdown-item">
          <span class="countdown-value">{{ days }}</span>
          <span class="countdown-label">Days</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ hours }}</span>
          <span class="countdown-label">Hours</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ minutes }}</span>
          <span class="countdown-label">Minutes</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-value">{{ seconds }}</span>
          <span class="countdown-label">Seconds</span>
        </div>
      </div>
    </div>
    
    <a href="https://ic.workbook.aicampus.co.za/view_events" target="_blank" class="cta-button text-decoration-none responsive-btn">Reserve Your Spot Now</a>


    <div class="feature-icons">
      <div class="feature-icon">
        <i class="fa-solid fa-network-wired"></i>
        <p>Network</p>
      </div>
      <div class="feature-icon">
        <i class="fas fa-graduation-cap"></i>
        <p>Learn</p>
      </div>
      <div class="feature-icon">
        <i class="fas fa-comments"></i>
        <p>Engage</p>
      </div>
    </div>
  </div>
  
</section>