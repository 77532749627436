export default {
  methods: {
    scrollToContact(cardName) {
      // Store the selected card name
      this.selectedCardName = cardName;
      
      // Scroll to contact section
      this.$nextTick(() => {
        const contactSection = document.getElementById('contact-us');
        if (contactSection) {
          contactSection.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      });
    }
  }
}