import mockupCorporate from '@/assets/images/orcaa-corp-mockup.png';
import mockupInternships from '@/assets/images/orcaa-intern-mockup.png';
import mockupPlus from '@/assets/images/orcaa-plus-mockup.png';
import mockupAssist from '@/assets/images/orcaa-assist-mockup.png';
import mockupRecruit from '@/assets/images/orcaa-recruit-mockup.png';

export default {
  data() {
    return {
      showNavbar: true,
      scrollAmount: 300,
      defaultColor: '#9747FF',
      defaultImage: '',
      activeImage: '',
      cards: [
        { color: "#0000FF", image: mockupAssist, title: "Assist", suite: "Orcaa.ai Suite" },
        { color: "#004aad", image: mockupInternships, title: "Internships", suite: "Orcaa.ai Suite" },
        { color: "#0097b2", image: mockupPlus, title: "Plus", suite: "Orcaa.ai Suite" }, 
        { color: "#453cf9", image: mockupCorporate, title: "X", suite: "Orcaa.ai Suite" },
      ],
      isNavbarCollapsed: true
    };
  },

  mounted() {
    this.defaultImage = this.$refs.heroSuite?.src;
    this.updateScrollButtons();
    
    // Add event listener to close navbar when clicking outside
    document.addEventListener('click', this.handleOutsideClick);

    // Update scroll buttons when window resizes
    window.addEventListener('resize', this.updateScrollButtons);
  },

  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('resize', this.updateScrollButtons);
    document.removeEventListener('click', this.handleOutsideClick);
  },

  methods: {
    
    scrollCards(direction) {
      const wrapper = this.$refs.cardsWrapper;
      const scrollAmount = direction === 'next' ? this.scrollAmount : -this.scrollAmount;
      wrapper.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      this.$nextTick(this.updateScrollButtons);
    },

    hoverCard(card) {
      this.$refs.purpleCircle.style.backgroundColor = card.color;
      this.$refs.heroSuite.src = card.image;
      this.$refs.heroSuite.style.transform = 'scale(1.1)';
    },

    resetHover() {
      this.$refs.purpleCircle.style.backgroundColor = this.defaultColor;
      this.$refs.heroSuite.src = this.defaultImage;
      this.$refs.heroSuite.style.transform = 'scale(1)';
    },

    clickCard() {
      const pricelistSection = document.getElementById("pricelist");
      if (pricelistSection) {
        pricelistSection.scrollIntoView({ behavior: "smooth" });
      }
    },

    updateScrollButtons() {
      if (!this.$refs.cardsWrapper) return;
      
      const wrapper = this.$refs.cardsWrapper;
      const maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
      
      if (this.$refs.prevBtn) {
        this.$refs.prevBtn.style.display = wrapper.scrollLeft <= 0 ? 'none' : 'block';
      }
      
      if (this.$refs.nextBtn) {
        this.$refs.nextBtn.style.display = wrapper.scrollLeft >= maxScroll - 1 ? 'none' : 'block';
      }
    },

    scrollToFeatures() {
      document.getElementById("features_section")?.scrollIntoView({ behavior: "smooth" });
    },

    scrollToMoreFeatures() {
      document.getElementById("more_features_section")?.scrollIntoView({ behavior: "smooth" });
    }
  },

  watch: {
    
  }
};